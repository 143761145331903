/**
 * Notification Service
 * <p>The Notification Service is used to notify users via Email, Push and or SMS messages</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Sub type of the web push message  Can be used to map a link in the front-end or render a different icon  0 = UserMentioned  1 = TaskCreationRequest  2 = TenderRelated  3 = SystemAutomationSuccess  4 = SystemAutomationFailed
 */
export enum WebPushMessageSubType {
  UserMentioned = 0,
  TaskCreationRequest = 1,
  TenderRelated = 2,
  SystemAutomationSuccess = 3,
  SystemAutomationFailed = 4,
}
